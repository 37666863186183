import {arrayCompare} from '../../utils/Utils'
const rootReducer = (
  state = {
    reduxTopLineups: [],
    reduxContests: [],
    editMode: false,
    selectedLineup: null,
    fighterData: [],
    user: null,
    dataTimestamp: null,
    selectedFighter: null,
    warnings: []
  },
  action
) => {
  switch (action.type) {
    case "SET_TOP_LINEUPS":
      state = { ...state, reduxTopLineups: [...action.payload] };
      break;
    case "SET_WARNINGS":
      state = { ...state, warnings: [...action.payload] };
      break;
    case "SET_DATA_TIMESTAMP":
      state = { ...state, dataTimestamp: action.payload };
      break;
    case "SET_FIGHTER_DATA":
      state = { ...state, fighterData: [...action.payload] };
      break;
    case "SET_CONTESTS":
      state = { ...state, reduxContests: [...action.payload] };
      break;
    case "SET_EDIT_MODE":
      state = { ...state, editMode: action.payload };
      break;
    case "SET_SELECTED_FIGHTER":
      state = { ...state, selectedFighter: action.payload };
      break;
    case "SET_SELECTED_LINEUP":
      if (state.editMode === true) {
        var selectedLineup = null;
        if (!(state.selectedLineup === action.payload)) {
          selectedLineup = action.payload;
        }
      }
      state = { ...state, selectedLineup: selectedLineup };
      break;
    case "SET_USER_ENTRIES":
      state = { ...state, userEntries: [...action.payload] };
      break;
    case "SET_USER":
      state = { ...state, user: action.payload };
      break;
    case "UPDATE_ENTRY":
      var temp = state.reduxContests
      for (let i = 0; i < temp.length; i++) {
        const contest = temp[i];
        for (let j = 0; j < contest.entriesArr.length; j++) {
          const entry = contest.entriesArr[j];
          if (entry.id === action.payload.id) {
            // Check if arrays are equal
            if (!arrayCompare(entry.lineup, action.payload.lineup)) {
              temp[i].entriesArr[j].lineup = action.payload.lineup
              temp[i].entriesArr[j].dirty = true
            }
          }
        }
      }
      state = { ...state, userEntries: [...temp] };
      break;
    default:
      break;
  }
  return state;
};

export default rootReducer;
