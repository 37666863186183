export function setTopLineups(topLineups) {
    return {
        type: "SET_TOP_LINEUPS",
        payload: topLineups
    };
}

export function setWarnings(warnings) {
    return {
        type: "SET_WARNINGS",
        payload: warnings
    };
}

export function setDataTimestamp(timestamp) {
    return {
        type: "SET_DATA_TIMESTAMP",
        payload: timestamp
    };
}

export function setContests(contests) {
    return {
        type: "SET_CONTESTS",
        payload: contests
    };
}

export function updateEntry(entry) {
    return {
        type: "UPDATE_ENTRY",
        payload: entry
    };
}

export function setEditMode(editMode) {
    return {
        type: "SET_EDIT_MODE",
        payload: editMode
    };
}

export function setSelectedFighter(selectedFighter) {
    return {
        type: "SET_SELECTED_FIGHTER",
        payload: selectedFighter
    };
}

export function setSelectedLineup(lineup) {
    return {
        type: "SET_SELECTED_LINEUP",
        payload: lineup
    };
}

export function setUserEntries(entries) {
    return {
        type: "SET_USER_ENTRIES",
        payload: entries
    };
}

export function setFighterData(fighterData) {
    return {
        type: "SET_FIGHTER_DATA",
        payload: fighterData
    };
}

export function setUser(user) {
    return {
        type: "SET_USER",
        payload: user
    };
}