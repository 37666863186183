import React from "react";
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { TwitterTimelineEmbed } from "react-twitter-embed";

const styles = () => ({
  root: {
    backgroundColor: "#F7FBFC",
    padding: "4px",
    border: "1px solid #07090C14",
    borderRadius: "3px",
    height: '600px'

  },
  title: {
    fontSize: 14,
    color: "#001328",
  },
  subTitle: {
    fontSize: 8,
    // paddingLeft: '8px',
    color: "#001328",
  },
  logo: {
    width: "32px",
  },
});

class ArticleList extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.root} direction="column" spacing={2}>
        <Grid
          item
          container
          spacing={3}
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography noWrap={true} className={classes.title}>
              Tweets
            </Typography>
            <Typography noWrap={true} className={classes.subTitle}>
              @southpawDFS
            </Typography>
          </Grid>

          <Grid
            item
            xs
            justify="flex-end"
            container
            alignContent="flex-end"
            direction="column"
          >
            <Grid item>
              <img className={classes.logo} src={"/smallfist.png"} alt="logo" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="southpawDFS"
            options={{ height: '450' }}
            noHeader={true}
            noFooter={true}
            borderColor={"#07090C14"}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(ArticleList);
