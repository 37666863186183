import moment from 'moment'

export const getAbbreviatedFighterName = (fullName) => {
  var strArr = fullName.split(" ");
  var abbrevStr = strArr[0][0] + ".";
  strArr.slice(1).forEach(element => {
    abbrevStr += " " + element;
  });
  return abbrevStr;
}

export const getFighterLastName = (fullName) => {
  var strArr = fullName?.split(" ");
  return strArr ? strArr[strArr.length - 1]: '';
}

export const arrayCompare = (arr1, arr2) => {
  return JSON.stringify(arr1) === JSON.stringify(arr2)
}

export const formatDateString = (dateString) => {
  return dateString ? moment(dateString + ' UTC').format('ddd, MMM Do YYYY, h:mma') : null
}