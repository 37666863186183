import { createStore, combineReducers } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import rootReducer from "./reducers/rootReducer";

const store = createStore(
  combineReducers({
    rootReducer,
  }),
  devToolsEnhancer()
);

export default store;
