import React from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Skeleton from '@material-ui/lab/Skeleton';

import { Typography } from "@material-ui/core";

import { BarChart, XAxis, YAxis, Tooltip, Bar } from "recharts";
import TopLineupList from "../components/TopLineupList";
import { connect } from "react-redux";
import {
  setTopLineups,
  setContests,
  setUser,
  setUserEntries,
  setFighterData,
  setDataTimestamp,
  setWarnings
} from "../store/actions/actions";
import ArticleList from "../components/ArticleList";

require("dotenv").config();

const drawerWidth = 450;
const scrollbarWidth = 15;

const styles = () => ({
  table: {
    minWidth: 650,
  },
  paper: {
    height: 600,
    width: "100%",
    overflow: "auto",
    overflowX: "hidden",
  },
  pointerCursor: {
    cursor: "pointer",
  },
  formControl: {
    minWidth: 120,
  },
  drawer: {
    width: drawerWidth,
    overflowY: "auto",
  },
  drawerWidth: {
    width: drawerWidth,
  },
  fighterName: {
    margin: "auto",
    textAlign: "center",
  },
  fighterPortrait: {
    display: "inline",
    height: "75px",
    width: "auto",
    // borderRadius: "25px",
  },
  tableItem: {
    maxHeight: 50,
  },
  lineupContainer: {
    height: 65,
  },
  imageCropper: {
    // position: "relative",
    // overflow: "hidden",
    // borderRadius: "50%",
    // height: 60,
    // width: 60,
  },
  textSalary: {
    color: "#77dd77",
  },
  root: {
    width: "100%",
    display: "flex",
    minHeight: "185px",
  },
  card: {
    width: drawerWidth,
    display: "flex",
    minHeight: "100px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cover: {
    width: 151,
  },

  hide: {
    display: "none",
  },
  shiftRight: {
    marginLeft: drawerWidth + scrollbarWidth,
  },
  shiftLeft: {
    marginLeft: "0px",
  },
  usageChart: {
    paddingBottom: 200,
  },
  highlightedRow: {
    backgroundColor: "lightGray",
  },
  dialog: {
    width: 1000,
  },
  toolbar: {
    backgroundColor: "#1C4E80",
  },
  rootContainer: {
    padding: "15px",
    // maxWidth: "1300px",
    minWidth: "375px",
  },
  // lineupContainer: {
  //   maxWidth: "830px",
  //   minWidth: "620px",
  // },
  columnsContainer: {
    minWidth: "350px",
    marginBottom: "25px",
  },
});

class UFC extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      topLineupsLoaded: false,
      dialogOpen: false,
      filterValue: "Rating",
      selectedRows: [],
      usageData: {},
      chartData: {},
      contests: [],
      selectedLineup: null,
      fighterData: [
        {
          name: "",
          winOdds: 0,
          finishOdds: 0,
          opponentName: "",
          salary: 0,
          score: 0,
        },
      ],
      lineupData: [
        [
          {
            name: "",
            winOdds: 0,
            finishOdds: 0,
            opponentName: "",
            salary: 0,
            score: 0,
          },
        ],
      ],
      userLineupsInit: false,
      userLineups: [
        [
          {
            name: "",
            winOdds: 0,
            finishOdds: 0,
            opponentName: "",
            salary: 0,
            score: 0,
          },
        ],
      ],
      activePage: 1,
      itemsPerPage: 15,
    };
    this.sortByWinOdds = this.sortByWinOdds.bind(this);
    this.selectLineup = this.selectLineup.bind(this);
    this.printTopLineups = this.printTopLineups.bind(this);
  }

  initUserData() {
    var email = localStorage.getItem('email')
    // var password = localStorage.getItem('password')
    var authToken = localStorage.getItem('authToken')
    // var userId = localStorage.getItem('userId')
    if (email) {
      this.props.setUser({
        email: email,
        // password: password,
        // userId: userId,
        authToken: authToken
      });
    }
    return new Promise(function (resolve, reject) {
      resolve('Hello World!')
    })
  }
  getUsageChartData() {
    console.log("getUsageChartData");
    // create a usage map with all fighter names
    let dict = {};
    this.state.fighterData.forEach((fighter) => {
      dict[fighter.name] = 0;
    });

    // loop through array of selected lineup indices
    this.state.selectedRows.forEach((i) => {
      // get lineup with that index
      let lineup = this.state.lineupData[i];
      lineup.forEach((fighter) => {
        dict[fighter.name] += 1;
      });
    });

    let chartData = [];

    Object.entries(dict).forEach(([key, value]) => {
      if (value > 0) {
        let splitted = key.split(" ");
        console.log("splitted", splitted);
        let lastName = splitted.pop();
        console.log("lastName", lastName);
        chartData.push({ name: lastName, uv: value });
      }
    });
    console.log("chartData", chartData);
    chartData.sort((a, b) => (a.uv < b.uv ? 1 : -1));

    this.setState({ usageData: dict });
    this.setState({ chartData: chartData });
    console.log("suageData", this.state.usageData);

    return chartData;
  }

  sortByWinOdds() {
    var temp = this.state.fighterData;
    temp.sort((a, b) => (a.winOdds < b.winOdds ? 1 : -1));
    this.setState({
      fighterData: temp,
    });
  }

  sortByRating() {
    var temp = this.state.fighterData;
    temp.sort((a, b) => (a.score < b.score ? 1 : -1));
    this.setState({
      fighterData: temp,
    });
  }

  sortByFinishOdds() {
    var temp = this.state.fighterData;
    temp.sort((a, b) => (a.finishOdds < b.finishOdds ? 1 : -1));
    this.setState({
      fighterData: temp,
    });
  }

  sortByUsage() {
    var temp = this.state.fighterData;
    temp.sort((a, b) => (a.usage < b.usage ? 1 : -1));
    this.setState({
      fighterData: temp,
    });
  }

  getUnusedFighterString() {
    var str = "";
    var unused = Object.entries(
      Object.fromEntries(
        Object.entries(this.state.usageData).filter(([, v]) => v === 0)
      )
    );
    unused.forEach((fighter) => (str += fighter[0] + ", "));
    return str;
  }

  handleDialogClose() {
    this.setState({
      dialogOpen: false,
    });
  }

  handleLineupClick(i) {
    let temp = this.state.selectedRows;
    if (temp.includes(i)) {
      const index = temp.indexOf(i);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else {
      temp.push(i);
    }
    this.setState({
      selectedRows: temp,
    });

    let temp2 = [];
    if (!this.state.userLineupsInit) {
      this.setState({
        userLineupsInit: true,
      });
    } else {
      temp2 = this.state.userLineups;
    }

    if (temp2.includes(this.state.lineupData[i])) {
      const index = temp2.indexOf(this.state.lineupData[i]);
      if (index > -1) {
        temp2.splice(index, 1);
      }
    } else {
      temp2.push(this.state.lineupData[i]);
    }
    this.setState({
      userLineups: temp2,
    });
    console.log(this.state.userLineups);
  }

  changeFilter = (event) => {
    this.setState({
      filterValue: event.target.value,
    });
    switch (event.target.value) {
      case "Rating": {
        this.sortByRating();
        break;
      }
      case "Win Odds": {
        this.sortByWinOdds();
        break;
      }
      case "Finish Odds": {
        this.sortByFinishOdds();
        break;
      }
      default: {
        break;
      }
    }
  };

  handlePageChange = (event, value) => {
    console.log(value);
    this.setState({
      activePage: value,
    });
  };

  printTopLineups = () => {
    console.log(this.props);
  };

  getFighterData = () => {
      var url;
      if (process.env.REACT_APP_DEBUG === 'false') {
        url = process.env.REACT_APP_API_URL + "/getTopLineups";
      } else {
        url = "./mockResponse.json";
      }

      axios.get(url
      )

        .then(
          (response) => this.setInitialLineupData(response.data))
        // this.setState({
        //     fighterData: response.data.fighterData.map((item) => ({
        //       name: item.name,
        //       winOdds: Math.round(item.winOdds),
        //       finishOdds: Math.round(item.finishOdds),
        //       opponentName: item.opponentName,
        //       salary: item.salary,
        //       score: item.score,
        //       usage: Math.round(response.data.usageDict[item.name]),
        //       imageUrl: item.imageUrl,
        //     })),
        //   //   lineupData: response.data.topLineups,
        //   // })
        // )
        .catch((err) => err);
  };

  setInitialLineupData = (data) => {
    this.props.setDataTimestamp(data.timestamp)
    this.props.setTopLineups(data.topLineups)
    this.props.setFighterData(data.fighterData.map((item) => ({
      name: item.name,
      winOdds: Math.round(item.winOdds),
      finishOdds: Math.round(item.finishOdds),
      opponentName: item.opponentName,
      salary: item.salary,
      score: item.score,
      usage: Math.round(data.usageDict[item.name]),
      imageUrl: item.imageUrl,
    })))
    this.props.setWarnings(data.warnings)
  }

  setInitialUserContestData = (data) => {
    for (let index = 0; index < data.length; index++) {
      const contest = data[index];
      contest.dirty = false
    }
    this.props.setContests(data);
    // var entries = [];
    // console.log("ENTRIES", entries);
    // this.props.setUserEntries(entries);
  };

  getContestData = () => {
    if (this.props.user) {

      var url;
      if (process.env.REACT_APP_DEBUG === 'false') {
        url = process.env.REACT_APP_API_URL + "/contests/UFC";
      } else {
        url = "./contests.json";
      }
      axios.post(url, {
        email: this.props.user.email,
        password: this.props.user.password,
        userId: this.props.user.userId,
        authToken: this.props.user.authToken
      }
      )
        .then((response) => this.setInitialUserContestData(response.data))
        .catch((err) => err);

    } else {
      console.log('no user')
    }

  };

  componentDidMount() {
    this.initUserData().then((response) => {
      this.getFighterData();
      // this.getContestData();
    })
  }

  selectLineup(lineup) {
    if (this.props.editMode === true) {
      if (this.state.selectedLineup === lineup) {
        this.setState({
          selectedLineup: null,
        });
      } else {
        this.setState({
          selectedLineup: lineup,
        });
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.props.reduxTopLineups?.length > 1 ? (
          <div>
            {console.log("refd:", this.props.reduxTopLineups)}
            <Grid
              className={classes.rootContainer}
              container
              direction="row"
              spacing={2}
              justify="center"
            >
              <Grid item xs={6} className={classes.columnsContainer}>
                <TopLineupList
                  onClickCard={this.selectLineup}
                  selectedLineup={this.state.selectedLineup}
                ></TopLineupList>
              </Grid>
              <Grid item xs={6} className={classes.columnsContainer}>
                {/* <ContestList
                  contestData={this.props.reduxContests}
                  selectedLineup={this.state.selectedLineup}
                ></ContestList> */}
                <ArticleList></ArticleList>
              </Grid>
            </Grid>
          </div>
        ) : (
            <Grid
              className={classes.rootContainer}
              container
              direction="row"
              spacing={2}
              justify="center"
            >          
              <Skeleton animation="wave" variant="rect" width="100%" height="650px"></Skeleton>
            </Grid>
          )}
        <Dialog
          onClose={(e) => this.handleDialogClose()}
          aria-labelledby="simple-dialog-title"
          open={this.state.dialogOpen}
          fullWidth={true}
          maxWidth={"lg"}
        >
          <DialogTitle id="simple-dialog-title">
            Usage in Selected Lineups
          </DialogTitle>

          {this.state.dialogOpen && this.state.selectedRows.length > 0 ? (
            <BarChart
              width={1500}
              height={300}
              data={this.state.chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis height={60} dataKey="name" angle={-45} textAnchor="end" />
              <YAxis domain={[0, this.state.selectedRows.length]} />
              <Tooltip />

              <Bar dataKey="uv" fill="#82ca9d" />
            </BarChart>
          ) : (
              <div>No lineups selected</div>
            )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                Unused Fighters
              </Typography>
            </Grid>
            <Typography>{this.getUnusedFighterString()}</Typography>
          </Grid>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reduxTopLineups: state.rootReducer.reduxTopLineups,
    reduxContests: state.rootReducer.reduxContests,
    editMode: state.rootReducer.editMode,
    user: state.rootReducer.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTopLineups: (topLineups) => {
      dispatch(setTopLineups(topLineups));
    },
    setWarnings: (warnings) => {
      dispatch(setWarnings(warnings));
    },
    setDataTimestamp: (timestamp) => {
      dispatch(setDataTimestamp(timestamp));
    },
    setContests: (contests) => {
      dispatch(setContests(contests));
    },
    setUserEntries: (entries) => {
      dispatch(setUserEntries(entries));
    },
    setFighterData: (fighterData) => {
      dispatch(setFighterData(fighterData));
    },
    setUser: (user) => {
      dispatch(setUser(user));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UFC));
