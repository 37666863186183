import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import {
  setEditMode, setFighterData,
  setDataTimestamp, setTopLineups, setWarnings
} from "../store/actions/actions";
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from "@material-ui/lab/Alert";

const styles = () => ({
  editModeLabel: {
    color: "#151D24",
  },
  toolbar: {
    backgroundColor: "#FFFFFF",
    minHeight: "42px",
  },
  generateButton: {
    backgroundColor: '#c23b22',
    color: 'black',
    fontWeight: 'bold',
    padding: '4px 16px',
    '&:hover': {
      backgroundColor: '#ad341f',
    },
    marginLeft: '4px',
  },
  disabledButton: {
    backgroundColor: '#F7FBFC',
    color: 'black',
    fontWeight: 'bold',
    padding: '4px 16px',
    marginLeft: '4px',
  }
});



class SecondaryNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      failure: false
    };
  }

  handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ success: false })
  };
  handleFailureClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ failure: false })
  };

  setLineupData = (data) => {
    this.props.setDataTimestamp(data.timestamp)
    this.props.setTopLineups(data.topLineups)
    this.props.setFighterData(data.fighterData.map((item) => ({
      name: item.name,
      winOdds: Math.round(item.winOdds),
      finishOdds: Math.round(item.finishOdds),
      opponentName: item.opponentName,
      salary: item.salary,
      score: item.score,
      usage: Math.round(data.usageDict[item.name]),
      imageUrl: item.imageUrl,
    })))
    this.props.setWarnings(data.warnings)
  }

  generateTopLineups = () => {
    if (!this.state.loading) {
      this.setState({ success: false })
      this.setState({ failure: false })
      this.setState({ loading: true })

      console.log('generate')
      var url = process.env.REACT_APP_API_URL + "/generateTopLineups";
      axios.post(url, {
        email: 'a7x_bwc@yahoo.com',
        password: 'Jujusmithschuster123'
      },
        // { headers: { 'authorizationToken': this.props.user.authToken } }
      )
        .then(
          (response) => {
            this.setState({ loading: false })
            if (response.status === 200) {
              this.setState({ success: true })
              console.log('Successfully generated')
              this.setLineupData(response.data)
            } else {
              this.setState({ failure: true })
              console.log('Failed generating')
            }
          })
        .catch((err) => {
          console.log('Failed generating')
        });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <AppBar position="static">
        <Toolbar className={classes.toolbar} variant="dense" disableGutters="true">
          {/* <FormControlLabel
            disabled
            className={classes.editModeLabel}
            control={
              <Switch
                checked={this.props.editMode}
                onChange={(e) => this.props.setEditMode(e.target.checked)}
                color="primary"
              />
            }
            label="Edit Mode"
          /> */}
          
          {(this.props.user?.email === 'brandincanfield@gmail.com' || this.props.user?.email === "justinkonicki@gmail.com") && this.props.reduxTopLineups?.length > 1?
            <Button variant="contained" color="secondary" className={classes.generateButton} disabled={this.state.loading} onClick={this.generateTopLineups}>Generate</Button>
            : <Button variant="disabled" color="secondary" className={classes.disabledButton} disabled={this.state.loading}>Generate</Button>}
          {this.state.loading && <CircularProgress size={30} />}
        </Toolbar>
        <Snackbar
          open={this.state.failure} autoHideDuration={6000} onClose={this.handleFailureClose}>
          <Alert open={true} severity="error">
            Error generating lineups
        </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.success} autoHideDuration={6000} onClose={this.handleSuccessClose}>
          <Alert open={true} severity={"success"}>
            Lineup generation succeeded
        </Alert>
        </Snackbar>
      </AppBar>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    reduxTopLineups: state.rootReducer.reduxTopLineups,
    editMode: state.rootReducer.editMode,
    user: state.rootReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEditMode: (editMode) => {
      dispatch(setEditMode(editMode));
    },
    setTopLineups: (topLineups) => {
      dispatch(setTopLineups(topLineups));
    },
    setDataTimestamp: (timestamp) => {
      dispatch(setDataTimestamp(timestamp));
    },
    setFighterData: (fighterData) => {
      dispatch(setFighterData(fighterData));
    },
    setWarnings: (warnings) => {
      dispatch(setWarnings(warnings));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SecondaryNavBar));
