import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { setUser } from "../store/actions/actions";
import { connect } from "react-redux";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";


const styles = (theme) => ({
  toolbar: {
    backgroundColor: "#1C4E80",
  },
  pointerCursor: {
    cursor: "pointer",
  },
  title: {
    fontSize: 14,
  },
});

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      password: "",
      username: "",
      errorLoginOpen: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      username: event.state.username,
      password: event.state.password,
    });
  }

  authenticate = (email, password) => {
    var url = process.env.REACT_APP_API_URL + "/getAuthToken";

    axios.post(url, {
      email: email,
      password: password
    }

    )
      .then(
        (response) => {
          if (response.status === 200) {
            var authToken = response.headers.authorizationtoken;
            localStorage.setItem('authToken', authToken)
            localStorage.setItem('email', email)
            this.props.setUser({
              email: email,
              authToken: authToken
            });
            this.props.onCancel()
          } else {
            console.log('Auth failed')
            this.setState({ errorLoginOpen: true })
            this.props.onCancel()
          }
        })
      .catch((err) => {
        console.log('Auth failed')
        this.setState({ errorLoginOpen: true })
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.authenticate(this.state.username, this.state.password)
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ errorLoginOpen: false })
  };

  render() {
    return (
      <Dialog open={this.props.dialogOpen} fullWidth>
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        <form onSubmit={this.handleSubmit}>
          <DialogContent>
            <TextField
              type="username"
              placeholder="Email"
              fullWidth
              name="username"
              variant="outlined"
              value={this.state.username}
              onChange={(event) =>
                this.setState({
                  [event.target.name]: event.target.value,
                })
              }
              required
              autoFocus
            />
            <TextField
              type="password"
              placeholder="Password"
              fullWidth
              name="password"
              variant="outlined"
              value={this.state.password}
              onChange={(event) =>
                this.setState({
                  [event.target.name]: event.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              className="button-block"
              onClick={this.props.onCancel}
            >
              Cancel
          </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="button-block"
            >
              Submit
          </Button>
          </DialogActions>
        </form>

        <Snackbar
          open={this.state.errorLoginOpen} autoHideDuration={6000} onClose={this.handleSnackbarClose}>
          <Alert open={true} severity="error">
            Error logging in
        </Alert>
        </Snackbar>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reduxTopLineups: state.rootReducer.reduxTopLineups,
    selectedLineup: state.rootReducer.selectedLineup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoginModal));
