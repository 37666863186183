import React from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { setEditMode, setSelectedFighter } from "../store/actions/actions";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import { BarChart, XAxis, YAxis, Bar, LabelList } from "recharts";
import { getAbbreviatedFighterName, getFighterLastName } from "../utils/Utils";
import Divider from "@material-ui/core/Divider";
import { FormControlLabel, FormControl, Radio, RadioGroup } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";


const styles = () => ({
  root: {
    overflowX: "scroll",
    border: '1px solid #07090C14',
    borderRadius: '3px',
    paddingBottom: '6px',
    backgroundColor: '#F7FBFC',
  },
  card: {
    height: "100%",
  },
  content: {
    height: "100%",
  },
  editModeLabel: {
    color: "#151D24",
  },
  toolbar: {
    backgroundColor: "#FFFFFF",
    minHeight: "42px",
  },
  imageCropper: {
    position: "relative",
    overflow: "hidden",
    borderRadius: "60%",
    width: "60px",
    height: "auto",
    // aspectRatio: 1,
    backgroundColor: "#07090C14",
  },
  fighterPortrait: {
    width: "100%",
  },
  rightColumn: {
    fontSize: "12px",
  },
  ratingText: {
    fontSize: " 11px",
  },
  salaryText: {
    fontSize: " 11px",
    marginLeft: "4px",
  },
  fighterName: {
    fontSize: "12px",
  },
  opponentName: {
    paddingLeft: "8px",
    fontSize: "11px",
    color: "#2B3A48"
  },
  fighterCard: {
    maxWidth: "230px",
    padding: "2px",
  },
  selectedFighterCard: {
    maxWidth: "230px",
    padding: "2px",
    backgroundColor: '#DAE0E6'
  },
  radioLabel: {
    color: '#001328',
    fontSize: '12px',
    // marginLeft: '0px '
  },
  winRadio: {
    // padding: '0px',
    // paddingLeft: '14px',
    color: '#779ecb'
  },
  ratingRadio: {
    // padding: '0px',
    // paddingLeft: '14px',
    color: '07090C'
  },
  finishRadio: {
    // padding: '0px',
    // paddingLeft: '14px',
    color: '#c23b22'
  },
  radioGroup: {
    backgroundColor: '#F7FBFC',
  }

});


class PlayersBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fighterData: [],
      selectedFilter: 'rating'
    };
    this.handleRadioChange = this.handleRadioChange.bind(this);

  }

  componentDidMount() {
    console.log('did mount', this.props)
    this.setState({ fighterData: this.props.fighterData })
  }

  selectFighter(fighterName) {
    if (this.props.selectedFighter === fighterName) {
      this.props.setSelectedFighter(null)
    } else {
      this.props.setSelectedFighter(fighterName)

    }
  }

  handleRadioChange(event) {
    let selectedFilter = event.target.value;
    this.setState({ selectedFilter: selectedFilter })

  }

  sortData(data) {
    if (this.state.selectedFilter === 'rating') {
      return data.sort((a, b) => (a.score < b.score) ? 1 : -1)
    } else if (this.state.selectedFilter === 'winOdds') {
      return data.sort((a, b) => (a.winOdds < b.winOdds) ? 1 : -1)
    } else if (this.state.selectedFilter === 'finishOdds') {
      return data.sort((a, b) => (a.finishOdds < b.finishOdds) ? 1 : -1)
    } else {
      return data
    }
  }

  render() {
    const { classes } = this.props;
    return (



      <Grid justify="center" alignItems="center" container direction="column">
        {this.props.fighterData?.length > 1 ?
          (<Grid item container>
            <Grid
              container
              wrap="nowrap"
              item
              xs={12}
              className={classes.root}
              direction="row"
              spacing={0}
            >
              {this.sortData(this.props.fighterData).map((fighter) => (
                // Start of card
                <Grid
                  item
                  xs
                  container
                  direction="row"
                  className={fighter.name === this.props.selectedFighter ? classes.selectedFighterCard : classes.fighterCard}
                  wrap="nowrap"
                  onClick={() => this.selectFighter(fighter.name)}
                >
                  {/* Left half of card */}
                  <Grid
                    item
                    xs={4}
                    container
                    direction="column"
                    alignItems="center"
                  // className={classes.fighterPortrait}
                  >
                    <Grid item container xs alignItems="center" className={classes.fighterPortrait}>
                      <Grid item>
                        <div className={classes.imageCropper}>
                          <img
                            className={classes.fighterPortrait}
                            src={fighter.imageUrl}
                            alt="new"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    className={classes.rightColumn}
                    item
                    xs
                    container
                    direction="column"
                    spacing={0}
                    wrap="nowrap"
                  >
                    <Grid item xs>
                      <Typography className={classes.fighterName}>
                        {getAbbreviatedFighterName(fighter.name)}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography className={classes.opponentName}>
                        {getFighterLastName(fighter.opponentName) ? ('vs ' + getFighterLastName(fighter.opponentName)): 'Opponent NA'}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <BarChart
                        width={125}
                        height={50}
                        tick={false}
                        data={[
                          {
                            Win: fighter.winOdds,
                            Finish: fighter.finishOdds,
                            WinLabel: "W",
                            FinishLabel: "F",
                          },
                        ]}
                        layout="vertical"
                      >
                        <XAxis type="number" hide="true" domain={[0, 100]} />
                        <YAxis type="category" dataKey="name" hide="true" />
                        {fighter.winOdds > 0 ? (
                          <Bar dataKey="Win" fill="#779ecb">
                            <LabelList dataKey="Win" position="right" />
                            <LabelList dataKey="WinLabel" position="insideLeft" />
                          </Bar>
                        ) : (
                            <Bar dataKey="Win" fill="#779ecb"></Bar>
                          )}

                        {fighter.finishOdds > 0 ? (
                          <Bar dataKey="Finish" fill="#c23b22">
                            <LabelList dataKey="Finish" position="right" />
                            <LabelList dataKey="FinishLabel" position="insideLeft" />
                          </Bar>
                        ) : (
                            <Bar dataKey="Finish" fill="#c23b22"></Bar>
                          )}
                      </BarChart>
                    </Grid>

                    <Grid item xs container direction="row" justify={"flex-start"}>
                      <Grid item>
                        <Typography className={classes.ratingText}>
                          {"Rating: " + Math.round(fighter.score)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.salaryText}>
                          {"Salary: $" + Math.round(fighter.salary)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" className={classes.radioGroup}>
              <FormControl component="fieldset">
                <RadioGroup row onChange={this.handleRadioChange} aria-label="position" name="position" defaultValue="rating">
                  <FormControlLabel
                    classes={{ label: classes.radioLabel }}
                    className={classes.radioLabel}
                    label="Rank"
                    labelPlacement="start"
                    value="rating"
                    control={<Radio className={classes.ratingRadio} size="small" color="black" />}
                  />
                  <FormControlLabel
                    classes={{ label: classes.radioLabel }}
                    className={classes.radioLabel}
                    label="Win"
                    labelPlacement="start"
                    value="winOdds"
                    control={<Radio className={classes.winRadio} size="small" color="#779ecb" />}
                  />
                  <FormControlLabel
                    classes={{ label: classes.radioLabel }}
                    className={classes.radioLabel}
                    label="Finish"
                    labelPlacement="start"
                    value="finishOdds"
                    control={<Radio className={classes.finishRadio} size="small" />}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          ) : <Grid item container>    <Skeleton animation="wave" variant="rect" width="100%" height="96px"></Skeleton></Grid>
        }
      </Grid>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    fighterData: state.rootReducer.fighterData,
    selectedFighter: state.rootReducer.selectedFighter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEditMode: (editMode) => {
      dispatch(setEditMode(editMode));
    },
    setSelectedFighter: (selectedFighter) => {
      dispatch(setSelectedFighter(selectedFighter));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PlayersBar));
