import React from "react";
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import LineupCard from "./LineupCard";
import { connect } from "react-redux";
import { setSelectedLineup } from "../store/actions/actions";
import { Typography } from "@material-ui/core";
import { formatDateString } from '../utils/Utils'

const styles = () => ({
  root: {
    backgroundColor: "#F7FBFC",
    padding: '4px',
    border: '1px solid #07090C14',
    borderRadius: '3px',
    height: '600px'
  },
  toolbar: {
    backgroundColor: "#1C4E80",
  },
  pointerCursor: {
    cursor: "pointer",
  },
  title: {
    fontSize: 14,
    // paddingLeft: '8px',
    color: '#001328',
  },
  subTitle: {
    fontSize: 8,
    // paddingLeft: '8px',
    color: '#001328',
  },
  timestamp: {
    fontSize: 10,
    // paddingLeft: '8px',
    color: "#2B3A48",
  },
  lineupCard: {
    border: "1px solid #07090C14"
  }
});

class TopLineupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsPerPage: 5,
      selectedRows: [],
      lineupData: this.props.lineupData,
      userLineupsInit: false,
    };
  }
  handlePageChange = (event, value) => {
    console.log(value);
    this.setState({
      activePage: value,
    });
  };
  handleDrawerOpen() {
    this.setState({
      open: true,
    });
  }
  handleDialogOpen() {
    this.getUsageChartData();
    this.setState({
      dialogOpen: true,
    });
  }

  handleLineupClick() {
    // let temp = this.state.selectedRows;
    // if (temp.includes(i)) {
    //   const index = temp.indexOf(i);
    //   if (index > -1) {
    //     temp.splice(index, 1);
    //   }
    // } else {
    //   temp.push(i);
    // }
    // this.setState({
    //   selectedRows: temp,
    // });
    // let temp2 = [];
    // if (!this.state.userLineupsInit) {
    //   this.setState({
    //     userLineupsInit: true,
    //   });
    // } else {
    //   temp2 = this.state.userLineups;
    // }
    // if (temp2.includes(this.state.lineupData[i])) {
    //   const index = temp2.indexOf(this.state.lineupData[i]);
    //   if (index > -1) {
    //     temp2.splice(index, 1);
    //   }
    // } else {
    //   temp2.push(this.state.lineupData[i]);
    // }
    // this.setState({
    //   userLineups: temp2,
    // });
    // console.log(this.state.userLineups);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root} direction="column" spacing={2}>
        <Grid item container spacing={3} justify="space-between" alignItems="center">
          <Grid item>
            <Typography noWrap={true} className={classes.title}>Top Generated Lineups</Typography>
            {this.props.selectedFighter? 
              <Typography noWrap={true} className={classes.subTitle}>Containing: {this.props.selectedFighter}</Typography>: <Typography noWrap={true} className={classes.subTitle}>* Filter the list by selecting a fighter above</Typography>}
          </Grid>

          {this.props.dataTimestamp ?
            <Grid item xs justify="flex-end" container alignContent="flex-end" direction="column">
              <Grid item>
                <Typography
                  className={classes.timestamp}
                >
                  Generated on
                    </Typography>
              </Grid>
              <Grid item>
                <Typography noWrap={true} className={classes.timestamp}>{formatDateString(this.props.dataTimestamp)}</Typography>
              </Grid>
            </Grid> : null}
        </Grid>


        {this.props.selectedFighter ? this.props.reduxTopLineups.filter(lineup => lineup.some(fighter => fighter.name === this.props.selectedFighter) === true)
          .slice(
            (this.state.activePage - 1) * this.state.itemsPerPage,
            (this.state.activePage - 1) * this.state.itemsPerPage +
            this.state.itemsPerPage
          )
          .map((lineup, i) => (
            <Grid item>
              <LineupCard
                rank={(this.state.activePage - 1) * this.state.itemsPerPage +
                  i + 1}
                className={classes.lineupCard}
                selected={lineup === this.props.selectedLineup}
                onClickCard={() => this.props.setSelectedLineup(lineup)}
                lineup={lineup}
              ></LineupCard>
            </Grid>
          )): 

          this.props.reduxTopLineups
          .slice(
            (this.state.activePage - 1) * this.state.itemsPerPage,
            (this.state.activePage - 1) * this.state.itemsPerPage +
            this.state.itemsPerPage
          )
          .map((lineup, i) => (
            <Grid item>
              <LineupCard
                rank={(this.state.activePage - 1) * this.state.itemsPerPage +
                  i + 1}
                className={classes.lineupCard}
                selected={lineup === this.props.selectedLineup}
                onClickCard={() => this.props.setSelectedLineup(lineup)}
                lineup={lineup}
              ></LineupCard>
            </Grid>
          ))
          
          }
        {/* {this.props.reduxTopLineups
          .slice(
            (this.state.activePage - 1) * this.state.itemsPerPage,
            (this.state.activePage - 1) * this.state.itemsPerPage +
            this.state.itemsPerPage
          )
          .map((lineup, i) => (
            <Grid item>
              <LineupCard
                rank={(this.state.activePage - 1) * this.state.itemsPerPage +
                  i + 1}
                className={classes.lineupCard}
                selected={lineup === this.props.selectedLineup}
                onClickCard={() => this.props.setSelectedLineup(lineup)}
                lineup={lineup}
              ></LineupCard>
            </Grid>
          ))} */}
        <Pagination
          shape="rounded"
          showFirstButton
          size={"small"}
          count={this.props.selectedFighter ? Math.ceil(this.props.reduxTopLineups.filter(lineup => lineup.some(fighter => fighter.name === this.props.selectedFighter) === true).length / this.state.itemsPerPage): Math.ceil(this.props.reduxTopLineups.length / this.state.itemsPerPage)}
          page={this.state.activePage}
          onChange={this.handlePageChange.bind(this)}
        />
      </Grid>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    reduxTopLineups: state.rootReducer.reduxTopLineups,
    selectedLineup: state.rootReducer.selectedLineup,
    dataTimestamp: state.rootReducer.dataTimestamp,
    selectedFighter: state.rootReducer.selectedFighter,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedLineup: (lineup) => {
      dispatch(setSelectedLineup(lineup));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TopLineupList));