import React from "react";
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import { getFighterLastName } from "../utils/Utils";
import { Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";

const styles = (theme) => ({
  imageCropper: {
    position: "relative",
    overflow: "hidden",
    borderRadius: "50%",
    width: "100%",
    backgroundColor: "#07090C14",
  },
  fighterPortrait: {
    width: "100%",
  },
  fighterNames: {
    fontSize: "12px",
    color: "#07090C",
  },
  scoreText: {
    fontSize: "10px",
    color: "#2B3A48",
    marginRight: "8px",
  },
  rankText: {
    fontSize: "10px",
    color: "#2B3A48",
    marginRight: "8px",
  },
  selected: {
    backgroundColor: "#d5e6f6",
    padding: '4px'
  },
  notSelected: {
    borderTop: '4px solid #DAE0E6',
    padding: '4px'
  },
  reserved: {
    minHeight: "55px"
  },
  stackedText: {
    fontSize: "10px",
    color: "#cf9382",
  },
});

class LineupCard extends React.Component {
  checkIfStackedLineup(lineup) {
    let stacked = false;

    lineup.forEach(fighter => {
      if (lineup.some(j => j.opponentName === fighter.name)) {
        stacked = true
      }
    });

    return stacked
  }

  formatFighterNames(lineup) {
    var finalStr = "";

    for (let index = 0; index < lineup.length; index++) {
      const player = lineup[index];
      finalStr += getFighterLastName(player.name);
      if (index !== lineup.length - 1) {
        finalStr += ", ";
      }
    }
    return finalStr;
  }

  getLineupScore(lineup) {
    var score = 0;
    lineup.forEach((player) => {
      score += player.winOdds + player.finishOdds;
    });
    return Math.round(score);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container item direction="column" spacing={0}>
        {this.props.lineup?.length > 0 ? (
          (this.props.user?.email !== "brandincanfield@gmail.com" && this.props.user?.email !== "justinkonicki@gmail.com")  &&
            this.props.rank <= 3 ? (
            <Paper className={`${classes.reserved} ${classes.notSelected}`}>
              <Grid item>
                {" "}
                <Typography
                  className={classes.fighterNames}
                  variant={"subtitle2"}
                >
                  Lineup {this.props.rank} Reserved
                </Typography>
              </Grid>
            </Paper>
          ) : (
            <Paper
              className={
                this.props.selected ? classes.selected : classes.notSelected
              }
              onClick={this.props.onClickCard}
            >
              <Grid item xscontainer direction="row">
                <Typography
                  className={classes.fighterNames}
                  variant={"subtitle2"}
                >
                  {this.formatFighterNames(this.props.lineup)}
                </Typography>
              </Grid>
              <Grid item xs={4} container direction="row">
                {this.props.lineup.map((player) => (
                  <Grid item xs>
                    <div className={classes.imageCropper}>
                      <img
                        className={classes.fighterPortrait}
                        src={player.imageUrl}
                        alt="new"
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
              <Grid item container direction="row">

                {this.props.rank ? (
                  <Typography className={classes.rankText} variant={"caption"}>
                    {"Rank: " + this.props.rank}
                  </Typography>
                ) : null}

                <Typography className={classes.scoreText} variant={"caption"}>
                  {"Score: " + this.getLineupScore(this.props.lineup)}
                </Typography>

                {this.checkIfStackedLineup(this.props.lineup) ? (
                  <Typography className={classes.stackedText} variant={"caption"}>
                    {"Stacked"}
                  </Typography>
                ) : null}

              </Grid>
            </Paper>
          )
        ) : (
          <Paper>
            <Grid item> Empty Lineup </Grid>
          </Paper>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.rootReducer.user,
  };
};

export default connect(
  mapStateToProps,
)(withStyles(styles)(LineupCard));
