import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/styles";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";

import { connect } from "react-redux";
import { setUser } from "../store/actions/actions";
import { Grid, Typography } from "@material-ui/core";

const styles = (theme) => ({
  toolbar: {
    backgroundColor: "#1C4E80",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  email: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  loginText: {
    fontSize: "11px",
  },
  subTitle: {
    fontSize: "9px",
  },
  pointerCursor: {
    cursor: "pointer",
  },
  timestamp: {
    fontSize: 10,
    color: "white",
  },
  logo: {
    width: "125px",
  },
  logoContainer: {
    marginLeft: '4px'
  },
  emailContainer: {
    marginRight: '4px'
  },
  registerOrLogin: {
    marginRight: '6px'
  }
});

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModalOpen: false,
    };
    this.closeLoginModal = this.closeLoginModal.bind(this);
    this.openLoginModal = this.openLoginModal.bind(this);
    this.closeRegisterModal = this.closeRegisterModal.bind(this);
    this.openRegisterModal = this.openRegisterModal.bind(this);
  }

  openLoginModal(e) {
    this.setState({
      loginModalOpen: true,
    });
  }

  openRegisterModal(e) {
    this.setState({
      registerModalOpen: true,
    });
  }

  logOut(e) {
    localStorage.setItem("authToken", null);
    localStorage.setItem("email", null);
    // localStorage.setItem('password', null)
    // localStorage.setItem('userId', null)
    this.props.setUser(null);
  }

  closeLoginModal(e) {
    this.setState({
      loginModalOpen: false,
    });
  }

  closeRegisterModal(e) {
    this.setState({
      registerModalOpen: false,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <AppBar position="static">
        <Toolbar className={classes.toolbar} disableGutters="true">
          <Grid container justify="space-between" wrap="nowrap">
            <Grid item container direction="row" alignItems="center" spacing={1} className={classes.logoContainer}>
              <Grid item>
                <img
                  className={classes.logo}
                  src={"/southpaw.png"}
                  alt="logo"
                />
              </Grid>
              {/* <Grid item xs container direction="column">
                <Grid item>
                  <Typography className={classes.title}>Southpaw</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.subTitle}>version 0.0.0-alpha</Typography>
                </Grid>
              </Grid> */}
            </Grid>

            <Grid item container justify="flex-end">
              {this.props.user ? (
                <Grid item container justify="center" alignItems="flex-end" direction="column">
                  <Grid item className={classes.registerOrLogin} container alignItems="flex-end" direction="column">
                    <Grid item>
                      <Typography
                        variant="h6"
                        className={classes.email}
                      >
                        {this.props.user.email}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        onClick={(e) => this.logOut(e)}
                        variant="h6"
                        className={`${classes.loginText} ${classes.pointerCursor}`}
                      >
                        Logout
                  </Typography>
                    </Grid>
                  </Grid></Grid>
              ) : (
                  <Grid item className={classes.registerOrLogin} container justify="center" alignItems="flex-end" direction="column">
                    <Grid item>
                      <Typography
                        onClick={(e) => this.openRegisterModal(e)}
                        variant="h6"
                        className={`${classes.title} ${classes.pointerCursor}`}
                      >
                        Register
                    </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        onClick={(e) => this.openLoginModal(e)}
                        variant="h6"
                        className={`${classes.loginText} ${classes.pointerCursor}`}
                      >
                        or Login
                    </Typography>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Toolbar>
        <LoginModal
          onCancel={this.closeLoginModal}
          dialogOpen={this.state.loginModalOpen}
        ></LoginModal>
        <RegisterModal
          onCancel={this.closeRegisterModal}
          dialogOpen={this.state.registerModalOpen}
        ></RegisterModal>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.rootReducer.user,
    dataTimestamp: state.rootReducer.dataTimestamp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => {
      dispatch(setUser(user));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NavBar));
