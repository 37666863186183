import React from "react";
import "./App.css";
import Grid from "@material-ui/core/Grid";
import UFC from "./pages/UFC";
import NavBar from "./components/NavBar";
import SecondaryNavBar from "./components/SecondaryNavBar";
import PlayersBar from "./components/PlayersBar";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import Collapse from '@material-ui/core/Collapse';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      alertOpen: true,
    };
    this.closeAlert = this.closeAlert.bind(this);
  }

  closeAlert() {
    this.setState({ alertOpen: false });
  }

  render() {
    return (
      <Grid container>
        <NavBar></NavBar>
        {this.props.reduxTopLineups?.length > 1 ? (
          <Collapse in={this.state.alertOpen} style={{width: "100%"}}>
            <Alert severity="warning" onClose={this.closeAlert}>
              {this.props.warnings.map((warning) => (
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    {'• ' + warning}
                  </Grid>
                </Grid>
              ))}
            </Alert>
          </Collapse>) : null}

        <SecondaryNavBar></SecondaryNavBar>
        <PlayersBar></PlayersBar>
        <Grid container justify={"center"}>
          <UFC></UFC>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    warnings: state.rootReducer.warnings,
    reduxTopLineups: state.rootReducer.reduxTopLineups,
  };
};

export default connect(
  mapStateToProps,
)(App);
